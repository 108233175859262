import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  pageData: any;
  constructor(public _activeRoute: ActivatedRoute,private _generalService: GeneralService) {
  }

  ngOnInit(): void {
    const pageSlug = this._activeRoute.snapshot.params['slug'];
    this._generalService.getEventDetail(pageSlug).subscribe(
      res => {
        this.pageData = res;
      },
      error => {
        console.log(error);
      }
    );

  }

}
