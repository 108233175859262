import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { GeneralService } from 'src/app/service/general.service';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations:[
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
    ])
  ]

})
export class SliderComponent implements OnInit {
  current = 0;
  imgList:any;

  constructor(
    private _generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.current = ++this.current % this.imgList.length;
    }, 2000);
    this._generalService.getSliderDetails().subscribe(
      res => {
        console.log(res);
        this.imgList = res;
      },
      error => {
        console.log(error);
        
      }
    );
  }

}
