<mat-card class="example-card">
  <mat-card-header>

    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>{{pageData?.title}}</mat-card-title>
    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
  </mat-card-header>
  <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
  <mat-card-content>
    <img style="width: 100%;height: auto;" mat-card-image [src]="pageData?.image"> <br>
    <p class="content-css">
      {{pageData?.content}}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <!-- <button mat-button>LIKE</button>
    <button mat-button>SHARE</button> -->
  </mat-card-actions>
</mat-card>
