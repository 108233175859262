import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar/top-bar.component';
import { FooterComponent } from './footer/footer.component';
import { IconComponent } from './icon/icon.component';
import { SliderComponent } from './slider/slider.component';
import { MenuComponent } from './menu/menu.component';
import { MaterialModule } from '../../lib/material/material.module';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [TopBarComponent, FooterComponent, IconComponent, SliderComponent, MenuComponent, MenuItemComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  exports: [TopBarComponent, FooterComponent, IconComponent, SliderComponent, MenuComponent, MaterialModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
