import { CoreService } from './../../../service/core.service';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-core-details',
  templateUrl: './core-details.component.html',
  styleUrls: ['./core-details.component.scss']
})
export class CoreDetailsComponent implements OnInit, AfterViewInit {
  contentData: any;
  facultyData: any = [];
  url = environment.url;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _coreService: CoreService,
    private activeRoute: ActivatedRoute

  ) {
  }
  ngAfterViewInit() {
    console.log('called');
  }
  ngOnInit(): void {
    const pageSlug = this._activeRoute.snapshot.params['slug'];

    console.log(pageSlug);
    this._coreService.getPageDetail(pageSlug).subscribe(
      res => {
        console.log(res);
        this.contentData = res;
      },
      error => {
        console.log(error);

      }
    );
    this.getFaculty(1);
    this.activeRoute.params.subscribe(routeParams => {
      console.log(routeParams);
      this._coreService.getPageDetail(routeParams.slug).subscribe(
        res => {
          console.log(res);
          // this.contentData = res.pipe(filter(num => num % 2 === 0));

          this.contentData = res;

        },
        error => {
          console.log(error);

        }
      );

    });
  }

  getFaculty(id) {
    console.log('id',id);
    this._coreService.getFaculty(id).subscribe(res => {
      this.facultyData = res;
      console.log(res);
    },
      err => {
        console.log(err);
      });
  }

  download(val){
    // var windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
     var url='http://gcbkorba.ac.in/media/'+val.fields.media_file;
     window.open(url, "_blank");
  }

  get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }



}
