import { Component , ChangeDetectorRef, EventEmitter, OnInit, OnChanges, SimpleChanges, ElementRef, Renderer2} from '@angular/core';
// import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  public _albums: any = [];
  constructor(/*private _lightbox: Lightbox*/) {
    for (let i = 1; i <= 4; i++) {
      const src = 'demo/img/image' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const thumb = 'demo/img/image' + i + '-thumb.jpg';
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };

      this._albums.push(album);
    }
  }

  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    // this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    // this._lightbox.close();
  }

}
