<footer style="background-color:#990000" class="footer pad-2">
  <div class="footer-section">
    <div>
      <ul>
        <h2>Contact :-</h2>
        <li>
          <mat-icon aria-hidden="false" aria-label="Example business icon"
            >business</mat-icon
          >
          {{footerData?.address}}
        </li>
        <li>
          <mat-icon aria-hidden="false" aria-label="Example business call"
            >call</mat-icon
          >

          {{footerData?.contact_number}}
        </li>
        <li>
          <mat-icon aria-hidden="false" aria-label="Example Email"
            >email</mat-icon
          >

          {{footerData?.email_id}}

        </li>
      </ul>
    </div>
    <div>
      <ul>
        <h2>
          Principal :-
        </h2>
        <li>{{principalData?.full_name}}</li>
        <li>
          <mat-icon aria-hidden="false" aria-label="Example business">business</mat-icon>
            {{principalData?.full_name}}
        </li>
        <li>
          <mat-icon aria-hidden="false" aria-label="Example call"
            >call</mat-icon>
          {{principalData?.mobile_number}}
        </li>
        <li>
          <mat-icon aria-hidden="false" aria-label="Example email">email</mat-icon>
          {{principalData?.email}}
        </li>
      </ul>
    </div>
    <div>
      <h2>
        Social Link :-
      </h2>
      <span *ngFor="let val of socialMediaData">
          <a [href]="val.url" target="_blank" >
            <img class="socialmediaicon" [src]="'http://gcbkorba.ac.in/api/media/'+(val?.logo).slice(71)" alt="{{val?.app_name}}" title="{{val?.app_name}}">
          </a> &nbsp;&nbsp;
      </span>
    </div>
    <div>
      <app-icon></app-icon>
    </div>
  </div>
  <hr>
  <div class="text-center">
    <div>
      Copyright &copy; 2020, Govt. College Bhaisma
    </div>
    <div>Developed By:- <a href="">CuriosityDesk</a></div>
  </div>
</footer>
