<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
	<!-- Position Column -->
	<ng-container matColumnDef="position">
		<th mat-header-cell *matHeaderCellDef> No. </th>
		<td mat-cell *matCellDef="let element"> {{element.position}} </td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="name">
		<th mat-header-cell *matHeaderCellDef> Name </th>
		<td mat-cell *matCellDef="let element"> {{element.name}} </td>
	</ng-container>

	<!-- Weight Column -->
	<ng-container matColumnDef="weight">
		<th mat-header-cell *matHeaderCellDef> Weight </th>
		<td mat-cell *matCellDef="let element"> {{element.weight}} </td>
	</ng-container>

	<!-- Symbol Column -->
	<ng-container matColumnDef="symbol">
		<th mat-header-cell *matHeaderCellDef> Symbol </th>
		<td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>