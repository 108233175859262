import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  baseUrl = environment.url


  constructor(
    private http: HttpClient
  ) { }

  getAppTitle(){
    return this.http.get(`${this.baseUrl}/general/application-title/`);
  }
  getFooterDetails(){
    return this.http.get(`${this.baseUrl}/general/footer-detail/`);
  }
  getLogo(){
    return this.http.get(`${this.baseUrl}/general/logo/`);
  }
  getPrincipalDetails(){
    return this.http.get(`${this.baseUrl}/general/principal-detail/`);
  }
  getSliderDetails(){
    return this.http.get(`${this.baseUrl}/general/slider-image/`);
  }
  getSocialLink(){
    return this.http.get(`${this.baseUrl}/general/social-link/`);
  }

  getMenu(){
    return this.http.get(`${this.baseUrl}/menu/menu/`);
  }

  getAllPages(){
    return this.http.get(`${this.baseUrl}/core/page/`);
  }

  getEvents(){
    return this.http.get(`${this.baseUrl}/core/event/`);
  }

  getEventDetail(slug){
    return this.http.get(`${this.baseUrl}/core/event/${slug}/`);
  }

}
