
<mat-card>
  <div class="card mb-3" >
    <div class="row no-gutters">
      <div class="col-md-4">
        <img [src]="(pageData?.media?.media[0]?.media_file).slice(30)" class="card-img img-card-css" alt="...">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title">{{pageData?.title}}</h5>
          <p class="card-text text-justify ">{{pageData?.content |  slice:0:300}}<span *ngIf="pageData?.content.length > 300">...</span></p>
          <button mat-raised-button color="primary">Read More</button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
