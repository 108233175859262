import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  logo:any;
  constructor(
    private _generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this._generalService.getLogo().subscribe(
      res => {
        console.log(res);
        this.logo = res[0];
      },
      error => {
        console.log(error);
      }
    );
  }

}
