import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  appTitle: any;
  constructor(
    private _generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this._generalService.getAppTitle().subscribe(
      res => {
        console.log(res);
        this.appTitle = res[0];
      },
      error => {
        console.log(error);

      }
    );

  }

}
