<!-- <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <span *ngIf="child.page && child.page.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="child.page">
        <span>{{ child.title }}</span>
      </button>
      <app-menu-item #menu [items]="child.page"></app-menu-item>
    </span>
    <span *ngIf="!child.page || child.page.length === 0">
      <button mat-menu-item (click)="child.onClick();">
        <span>{{ child.title }}</span>
      </button>
    </span>
  </span>
</mat-menu> -->

<!--
<span *ngFor="let item of items">
  <span *ngIf="item.page && item.page.length > 0">
    <button mat-button>
      {{item.title}}
    </button>
    <app-menu-item #menu [newItems]="item.page"></app-menu-item>
  </span>
  <span *ngIf="!item.page || item.page.length === 0">
    <button mat-button color="">
      {{item.title}}
    </button>
  </span>
</span> -->

<!-- <div class="dropdown" *ngFor="let item of items">
  <button class="dropbtn">{{item.title}}</button>
  <div class="dropdown-content" >
    <a *ngFor="let child of item.page" [routerLink]="[child.fields.slug]"  routerLinkActive="router-link-active" >{{child.fields.title}}</a>
  </div>
</div> -->

<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
<mat-toolbar style="background-color: #982124; color: #fff; display: inline-table;white-space: normal;" class="example-toolbar">
  <button mat-icon-button (click)="snav.toggle()" class="menuHide"><mat-icon>menu</mat-icon></button>
  <h1 class="example-app-name" [routerLink]="['/']">{{appTitle?.english_title}}</h1>

  <span class="example-spacer"></span>
  <button class="dropbtn menuDrop" [routerLink]="['/']">HOME</button>
  <div class="dropdown menuDrop" *ngFor="let item of items">
    <button class="dropbtn" *ngIf="!item.link">{{item.title | uppercase}}</button>
    <button class="dropbtn" *ngIf="item.link" (click)="goToLink(item.link)">{{item.title | uppercase}}</button>
    <div class="dropdown-content">
      <a *ngFor="let child of item.page" [routerLink]="[child.fields.slug]"  routerLinkActive="router-link-active" >{{child.fields.title | uppercase}}</a>
    </div>
  </div>


</mat-toolbar>

<mat-sidenav-container class="example-sidenav-container"
                       [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
  <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
               [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
    <mat-nav-list>
      <span *ngFor="let item of items">
        <span *ngIf="item.page && item.page.length > 0">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <!-- Cabeceras del submenu -->
                  <div fxLayout="row" fxLayoutAlign="space-between center" >
                    {{item.title}}
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span *ngFor="let child of item.page">
                <mat-list-item [routerLink]="[child.fields.slug]" (click)="snav.toggle()">
                  <!-- Entradas de cada submenú  -->
                  <div fxLayout="row" fxLayoutAlign="space-between center" >
                    {{child.fields.title}}
                  </div>
                </mat-list-item>
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </span>
        <span *ngIf="!item.page || item.page.length === 0">
          <mat-list-item (click)="snav.toggle()">
            <!-- Entradas principales -->
            <div *ngIf="!item.link" fxLayout="row" fxLayoutAlign="space-between center" class="fs-15">
              {{item.title}}
            </div>
            <div *ngIf="item.link" (click)="goToLink(item.link)" fxLayout="row" fxLayoutAlign="space-between center" class="fs-15">
              {{item.title}}
            </div>
          </mat-list-item>
        </span>
      </span>
    </mat-nav-list>
  </mat-sidenav>
  </mat-sidenav-container>
</div>
