<div *ngIf="contentData?.slug == 'faculty'">
  <mat-card class="grid-container-card">
    <mat-card class="hover-class  example-card" *ngFor="let val of facultyData">
      <div class="text-center">
        <img *ngIf="val.profile_image" [src]="val.profile_image" alt="" class="profile-img">
        <img *ngIf="!val.profile_image" src="../../../../assets/img/no-image.png" alt="" class="profile-img">
      </div>

      <mat-card-content class="text-center">
        <h3>{{val.full_name | uppercase}}</h3>
        <h5>{{val?.education}}</h5>
        <h5>{{val?.job_title}}</h5>
        <h5>{{val?.contact_number}}</h5>
      </mat-card-content>
    </mat-card>
  </mat-card>
</div>


<mat-card  *ngIf="contentData?.slug != 'faculty' && contentData?.slug != 'iqac' && contentData?.slug != 'aqar' && contentData?.slug != 'download'" >

  <mat-card>
    <h2 class="text-center ff-lb">
      {{contentData?.title}}
    </h2>
    <h3 class="text-center ff-lblack">
      {{contentData?.sub_title}}

    </h3>
    <mat-card-content>
      <div class="text-center" *ngIf="contentData?.media && contentData?.media?.length == 1">
        <!-- {{get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file)}} -->
        <!-- For Image -->
        <img *ngIf="get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file) == 'png' || get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file) == 'jpg' || get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file) == 'jpeg'"
        [src]="'http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file" alt="{{contentData?.media[0].fields.title}}" class="img-core-detail">

        <!-- For File -->
        <mat-list role="list" *ngIf="get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file) != 'png' && get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file) != 'jpg' && get_url_extension('http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file) != 'jpeg'">
          <mat-list-item role="listitem" style="cursor: pointer;" (click)="download(contentData?.media[0])">{{contentData?.media[0].fields.title}}  <mat-icon class="ml-35" style="color: #982124;">cloud_download</mat-icon></mat-list-item>
        </mat-list>
      </div>

      <div class="text-center" *ngIf="contentData?.media && contentData?.media?.length > 1">
        <div class="col-md-3">
          <mat-card class="grid-container-card">
            <mat-card *ngFor="let image of contentData?.media">
              <!-- For Image -->
              <img class="gallery-image" *ngIf="get_url_extension('http://gcbkorba.ac.in/media/'+image.fields.media_file) == 'png' || get_url_extension('http://gcbkorba.ac.in/media/'+image.fields.media_file) == 'jpg' || get_url_extension('http://gcbkorba.ac.in/media/'+image.fields.media_file) == 'jpeg'"
              [src]="'http://gcbkorba.ac.in/media/'+image.fields.media_file" alt="{{image.fields.title}}" (click)="download(image)">

              <!-- For File -->
              <mat-list role="list" *ngIf="get_url_extension('http://gcbkorba.ac.in/media/'+image.fields.media_file) != 'png' && get_url_extension('http://gcbkorba.ac.in/media/'+image.fields.media_file) != 'jpg' && get_url_extension('http://gcbkorba.ac.in/media/'+image.fields.media_file) != 'jpeg'">
                <mat-list-item role="listitem" style="cursor: pointer;" (click)="download(image)"> <mat-icon class="ml-35" style="color: #982124;">cloud_download</mat-icon></mat-list-item>
              </mat-list>

              <h3 class="card-title text-center">{{image.fields.title}}
              </h3>
            </mat-card>

          </mat-card>
        </div>
      </div>



      <p class="content-css">
        {{contentData?.content}}
      </p>
    </mat-card-content>
  </mat-card>

</mat-card>

<!-- For IQAC and AQR -->
<mat-card  *ngIf="contentData?.slug == 'iqac'  || contentData?.slug == 'aqar' || contentData?.slug == 'download' ">
  <mat-card>
    <h2 class="text-center ff-lb">
      {{contentData?.title}}
    </h2>
    <h3 class="text-center ff-lblack">
      {{contentData?.sub_title}}

    </h3>
    <mat-card-content>
      <div class="text-center" *ngIf="contentData?.media && contentData?.media?.length">

        <mat-list role="list" *ngFor="let val of contentData.media">
          <mat-list-item role="listitem" style="cursor: pointer;"  (click)="download(val)">{{val?.fields?.title}}  <mat-icon class="ml-35" style="color: #982124;">cloud_download</mat-icon></mat-list-item>
        </mat-list>

        <!-- <div class="" *ngFor="let val of contentData.media">
          <div (click)="download(val)"> <h5>{{val?.fields?.title}} <span class="download-css"><mat-icon >download</mat-icon></span></h5> </div>
        </div> -->
        <!-- <img [src]="'http://gcbkorba.ac.in/media/'+contentData.media[0].fields.media_file" alt="{{contentData?.media[0].fields.title}}" class="img-core-detail"> -->
      </div>

      <p class="content-css">
        {{contentData?.content}}
      </p>
    </mat-card-content>
  </mat-card>

</mat-card>
