import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreDetailsComponent } from './view/core/core-details/core-details.component';
import { LandingPageComponent } from './view/core/landing-page/landing-page.component'
import { EventsComponent } from './view/core/events/events.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: ':slug', component: CoreDetailsComponent},
  { path: 'event/:slug', component: EventsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
