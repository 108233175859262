import { Component, OnInit , Input} from '@angular/core';
import { NavItem } from 'src/app/view/nav-item';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input() newItems: NavItem[];
  items: any =[];

  constructor() { }

  ngOnInit(): void {
    this.items = this.newItems;
  }

}
