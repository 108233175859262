import { BrowserModule } from '@angular/platform-browser';
import { NgModule , ChangeDetectorRef, EventEmitter, OnInit, OnChanges, SimpleChanges, ElementRef, Renderer2} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../app/lib/material/material.module';
import { CoreModule } from './view/core/core.module';
import { SharedModule } from './view/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import {  MatButtonModule } from '@angular/material/button';
import {  MatSidenavModule } from '@angular/material/sidenav';
import {  MatToolbarModule } from '@angular/material/toolbar';
// import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    MatMenuModule,
    FlexLayoutModule,
    MatIconModule, MatButtonModule, MatSidenavModule, MatToolbarModule
    // LightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }
