import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/service/general.service';
import {ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  items: any = [];
  newItems :any;
  // @ViewChild('childMenu') public childMenu;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  appTitle: any;

  constructor(
    private _generalService: GeneralService,
    public router : Router,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
  ) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit(): void {
    this._generalService.getMenu().subscribe(
      res => {
        this.items = res;
        console.log('menu',this.items);
      },
      error => {
        console.log(error);
      }
    );

    this._generalService.getAppTitle().subscribe(
      res => {
        console.log(res);
        this.appTitle = res[0];
      },
      error => {
        console.log(error);

      }
    );

  }

  routePage(item){
    this.router.navigate([item.slug]);
  }

    ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

}
