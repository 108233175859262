<!-- <mat-toolbar style="background-color: #982124; color: #fff" class="app-top-bar top-bar"> -->
  <!-- <mat-toolbar-row> -->
    <!-- <span [routerLink]="['/']">{{appTitle?.english_title}}</span> -->
    <!-- <span class="example-spacer"></span> -->
    <app-menu ></app-menu>
  <!-- </mat-toolbar-row> -->
<!-- </mat-toolbar> -->
<!-- style="margin-right: 5rem;" -->

<!-- <div>
  <mat-toolbar color="primary">
    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <a mat-button class="companyName" routerLink="/">
      <span [routerLink]="['/']">{{appTitle?.english_title}}</span>
    </a>
    <span class="example-spacer"></span>
    <div fxShow="true" fxHide.lt-md="true">
      <a mat-button routerLink="/about-us">About us</a>
      <a mat-button routerLink="/prices">Prices</a>
      <a mat-button routerLink="/start-page">Start page</a>
      <a mat-button routerLink="/offer">Offer</a>
      <a mat-button routerLink="/contact">Contact</a>
    </div>

  </mat-toolbar>
  <mat-sidenav-container fxFlexFill class="example-container">

    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
      <div fxLayout="column">
        <a mat-button routerLink="/about-us">About us</a>
        <a mat-button routerLink="/prices">Prices</a>
        <a mat-button routerLink="/start-page">Start page</a>
        <a mat-button routerLink="/offer">Offer</a>
        <a mat-button routerLink="/contact">Contact</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      Awesome content
    </mat-sidenav-content>
  </mat-sidenav-container>
</div> -->
