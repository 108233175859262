import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  header:any;
  pageData: any = [];
  eventsData: any = [];
  showLoader = false;

  constructor(
    private _generalService: GeneralService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this._generalService.getAppTitle().subscribe(
      res => {
        this.showLoader = false;
        this.header = res[0];
        console.log(this.header);
      },
      error => {
        this.showLoader = false;
        console.log(error);
      }
    );

    this._generalService.getAllPages().subscribe(
      res => {
        this.pageData = res;
        console.log(res);
      },
      error => {
        console.log(error);
      }
    );
    this._generalService.getEvents().subscribe(
      res => {
        this.eventsData = res;
        console.log(res);
      },
      error => {
        console.log(error);
      }
    );
  }

  goToEvent(slug: string){
    this.router.navigate(['event/'+slug]);
  }

}
