import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerData: any;
  principalData: any;
  socialMediaData: any = [];

  constructor(
    private _generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this._generalService.getFooterDetails().subscribe(
      res => {

        this.footerData = res[0];
        console.log(this.footerData);
      },
      error => {
        console.log(error);

      }
    );
    this._generalService.getPrincipalDetails().subscribe(
      res => {
        this.principalData = res[0];
        console.log(this.principalData);

      },
      error => {
        console.log(error);

      }
    );

    this._generalService.getSocialLink().subscribe(
      res => {
        this.socialMediaData = res;
        console.log(this.socialMediaData);

      },
      error => {
        console.log(error);

      }
    );



  }


}
