import { MaterialModule } from './../../lib/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { EventsComponent } from './events/events.component';
import { CoreDetailsComponent } from './core-details/core-details.component';
import { SharedModule } from '../shared/shared.module';
import { TableComponent } from './table/table.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { NoticeComponent } from './notice/notice.component';
import { TenderComponent } from './tender/tender.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'


@NgModule({
  declarations: [LandingPageComponent, EventsComponent, CoreDetailsComponent, TableComponent, GalleryComponent, PageContainerComponent, NoticeComponent, TenderComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CoreModule { }
