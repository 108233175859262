<mat-spinner *ngIf="showLoader" class="center-spinner"></mat-spinner>

<div class="page-margin">
  <div class="text-center pad-2">
    <h1>
      {{header?.english_title}}
    </h1>
    <h4> {{header?.sub_title}}
      <a mat-raised-button style="background-color: #982124 !important; color: #fff !important" href="https://voters.eci.gov.in" target="_blank">Voter Portal</a>
    </h4>
    <h4>{{header?.naac_title}}</h4>
    <div class="icon">
      <app-icon> </app-icon>
    </div>
    <h1>
      {{header?.hindi_title}}
    </h1>
  </div>
  <div style="padding: 20px 0px">
    <app-slider></app-slider>
  </div>
  <!-- <div class="pad-2">
    <app-page-container *ngFor="let val of pageData" [data]="val" class="col-12">
    </app-page-container>
  </div>
  <div class="pad-2">
    <app-table></app-table>
  </div>
  <div class="pad-2">
    <app-gallery></app-gallery>
  </div> -->
  <div class="pad-2">
    <div class="grid-container">
      <div *ngFor="let val of eventsData" class="grid-item">
        <!-- <app-events *ngFor="let val of eventsData" [data]="val"></app-events> -->
        <a class="text-dec-none">
        <mat-card class="example-card" style="
          min-width: 20rem;
          max-width: 20rem;
          min-height: 20rem;">
          <mat-card-header>
            <mat-card-title style="text-align: center;">{{val?.title}}</mat-card-title>
          </mat-card-header>
          <img mat-card-image class="imgevent" [src]="val?.image">
          <mat-card-content>
            <p>
              {{val?.content |  slice:0:110}} <span *ngIf="val.content.length > 110">...</span>
              &nbsp;&nbsp;
              <span *ngIf="val.content.length > 110" (click)="goToEvent(val.slug)" class="card-text pull-right btn btn-primary">
                <a  class="read-more">Read More</a>
              </span>
            </p>

          </mat-card-content>
          <mat-card-actions>
          </mat-card-actions>
        </mat-card>
      </a>
      </div>
    </div>
  </div>
</div>
