import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CoreService {
  baseUrl = environment.url

  constructor(
    private http: HttpClient
  ) { }
  getPageDetail(slug){
    return this.http.get(`${this.baseUrl}/core/page/${slug}/`);
  }

  getFaculty(id){
    // return this.http.get('/api/faculty/profilebydepartment/'+ id);
    return this.http.get(`${this.baseUrl}/faculty/Faculty/`);
  }
}
